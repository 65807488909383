<template>
  <div :class="[containerClass, { 'no-labels': !labels, disabled: disabled }]">
    <div
      v-for="(item, index) of events"
      :key="getKey(item, index)"
      class="p-timeline-event"
      :class="{
        'current-event': item === currentEvent,
        'last-processed-event': index < events.length && events[index + 1] === currentEvent,
      }"
    >
      <div class="p-timeline-event-opposite" v-if="labels && opposite">
        <slot
          name="opposite"
          :item="item"
          :index="index"
          :current="item === currentEvent"
          :past="index < processedEvents.length"
        ></slot>
      </div>
      <div class="p-timeline-event-separator">
        <slot
          name="marker"
          :item="item"
          :index="index"
          :current="item === currentEvent"
          :past="index < processedEvents.length"
        >
          <div class="p-timeline-event-marker"></div>
        </slot>
        <div
          v-if="index !== events.length - 1 || layout === 'vertical'"
          class="p-timeline-event-connector"
        ></div>
      </div>
      <div class="p-timeline-event-content" v-if="labels">
        <slot
          name="content"
          :item="item"
          :index="index"
          :current="item === currentEvent"
          :past="index < processedEvents.length"
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ObjectUtils } from "primevue/utils";

export default {
  name: "Timeline",
  components: {},
  props: {
    align: {
      mode: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    labels: {
      type: Boolean,
      default: true,
    },
    opposite: {
      type: Boolean,
      default: true,
    },
    processedEvents: Array,
    pendingEvent: Object,
    pendingEvents: Array,
    remainingEvents: Array,
    collapsed: Boolean,
    disabled: Boolean,
    dataKey: null,
    showPending: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    events: function () {
      var noPendingEventResult = [
        ...this.processedEvents,
        ...((this.processedEvents.length === 1 && this.layout === "horizontal" && [{}]) || []),
      ];

      if ((!this.pendingEvent && !this.pendingEvents) || this.showPending === false) {
        return noPendingEventResult;
      }

      var existingPendingEventResult = [
        ...this.processedEvents,
        ...(this.pendingEvent ? [this.pendingEvent] : this.pendingEvents),
        ...(this.remainingEvents || [{}]),
      ];

      return existingPendingEventResult;
    },
    currentEvent: function () {
      return this.pendingEvent || this.pendingEvents[0];
    },
    containerClass() {
      return ["p-timeline p-component", "p-timeline-" + this.align, "p-timeline-" + this.layout];
    },
  },
  methods: {
    getKey(item, index) {
      return this.dataKey ? ObjectUtils.resolveFieldData(item, this.dataKey) : index;
    },
  },
};
</script>

<style scoped lang="scss">
.p-timeline {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  &.disabled {
    opacity: 0.5;
  }
}

.p-timeline-left .p-timeline-event-opposite {
  text-align: right;
}

.p-timeline-left .p-timeline-event-content {
  text-align: left;
}

.p-timeline-right .p-timeline-event {
  flex-direction: row-reverse;
}

.p-timeline-right .p-timeline-event-opposite {
  text-align: left;
}

.p-timeline-right .p-timeline-event-content {
  text-align: right;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) {
  flex-direction: row-reverse;
}

.p-timeline-vertical.p-timeline-alternate
  .p-timeline-event:nth-child(odd)
  .p-timeline-event-opposite {
  text-align: right;
}

.p-timeline-vertical.p-timeline-alternate
  .p-timeline-event:nth-child(odd)
  .p-timeline-event-content {
  text-align: left;
}

.p-timeline-vertical.p-timeline-alternate
  .p-timeline-event:nth-child(even)
  .p-timeline-event-opposite {
  text-align: left;
}

.p-timeline-vertical.p-timeline-alternate
  .p-timeline-event:nth-child(even)
  .p-timeline-event-content {
  text-align: right;
}

.p-timeline-event {
  display: flex;
  position: relative;
  min-height: 70px;
}

.p-timeline-event:last-child {
  min-height: 0;
}

.p-timeline-event-opposite {
  flex: 1;
  padding: 0 1rem;
}

.p-timeline-event-content {
  flex: 1;
  padding: 0 1rem;
}

.p-timeline-event-separator {
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.p-timeline-event-marker {
  display: flex;
  align-self: baseline;
}

.p-timeline-event-connector {
  flex-grow: 1;
}

.p-timeline-horizontal {
  flex-direction: row;
}

.p-timeline-horizontal .p-timeline-event {
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.p-timeline-horizontal .p-timeline-event:last-child {
  flex: 0;
}

.p-timeline-horizontal .p-timeline-event-separator {
  flex-direction: row;
}

.p-timeline-bottom .p-timeline-event {
  flex-direction: column-reverse;
}

.p-timeline-horizontal.p-timeline-alternate .p-timeline-event:nth-child(even) {
  flex-direction: column-reverse;
}
</style>
